/* You can add global styles to this file, and also import other style files */

@import "~ngx-toastr/toastr";
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

/*@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500&display=swap');
*{
  font-family: 'Barlow', sans-serif;

}*/

//AGREGADO
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
* {
    font-family: "Montserrat", sans-serif;
}
:root {
    --secondary: #ec6333;
  }
.version-app{
    font-size: 14px;
}
.my-custom-cell-global {
    background: rgb(236,99,52);
}

.contenedorComponents {
    background: white !important;
    min-height: 100vh;
}

.titulo span {
    color: var(--mq-txt-color, rgb(255, 102, 36));
    font-weight: bold;
    font-family: Barlow, serif;
    font-size: 25px;
}

.subtitulo span {
    color: var(--mq-txt-color, #2e1572);
    font-weight: bold;
    font-family: Barlow, serif;
    font-size: 25px;
}

.text-olvide {
    padding-left: 0px;
}

.centrado-inputs {
    width: 84%;
    padding-left: 25%;
}

.label-login {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 14px;
    color: #000;
}

.label-register {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 14px;
    color: rgb(255, 255, 255);
}

.label-login__company {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 14px;
    color: #000;
}

.color-white-BIENVENIDO h1 {
    color: #1d124f !important;
    color: #1d124f !important;
    font-size: 202px !important;
    padding-top: 21% !important;
}

.text-info--registrer {
    color: #fff;
    font-size: 40px;
    padding-top: 11% !important;
    font-weight: bold;
}

.titulo-register {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 4% !important;
}

.titulo-register--driver {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 4% !important;
}

.titulo-register--driverconfirmar {
    font-size: 31px;
    font-weight: bold;
    margin-bottom: 4% !important;
}

.acepto-registrer {
    color: #000;
    font-weight: bold;
}

.text-center-ingresar {
    text-align: center !important;
    font-weight: bold;
    font-size: 33px;
    font-family: "Barlow Semi Condensed", sans-serif;
    padding-top: 15%;
}

.text-center-ingresar-company {
    text-align: center !important;
    font-weight: bold;
    font-size: 33px;
    font-family: "Barlow Semi Condensed", sans-serif;
    padding-top: 124px !important;
}

.text-requisitos--driver {
    font-weight: bold;
    font-size: 24px;
    font-style: italic;
}

h4 {
    font-family: "Barlow", sans-serif;
}

#themecolors a {
    cursor: pointer;
}

.invalid-feedback {
    display: block !important;
}

.logo-kamgus {
    width: 100%;
    margin-bottom: 30px;
}

.login-box {
    width: 390px;
    margin: 0 auto;
    margin-top: -32px !important;
}

.logo-white-register {
    width: 40%;
}

.mg-logo {
    margin: 80px 0px;
}

.title-register {
    color: white;
    font-size: 34px;
    font-weight: 600;
    margin: 30px 0px;
}

.p-register {
    color: white;
}

.card-kg {
    border-radius: 15px !important;
    border: 1px solid #dcdcdc !important;
    box-shadow: 0px 5px 2px #eee !important;
}


.modal-border{
    border-radius: 16px !important;
   
}

.modal-header-border{
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

h4 {
    color: #1d124f;
    font-size: 22px;
    font-weight: 500;
}

.btn_ojo button {
    /*bton del ojo*/
    padding: 7px 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    margin: 12px 1px 9px -56px;
    background-color: #f7f6f6;
}

.input-format {
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 2px #eee;
    padding: 10px;
    margin: 25px 0px 10px 0px;
}

.icons-wallet {
    color: rgb(18, 6, 57);
    font-size: 26px;
    margin-top: 38px;
    cursor: pointer;
}

.card-services {
    width: 320px;
    position: absolute;
    top: 70px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 20px;
    box-shadow: 2px 3px 2px #bfbfbf !important;
    left: 10px;
}

.card-title-k {
    background-color: #e6e6e6;
    color: #1d124f;
    text-align: center;
    font-weight: 600;
    position: relative;
    margin-top: -20px;
    margin-left: -20px;
    width: 320px;
    padding: 10px;
    font-size: 22px;
}

.input-group-addon {
    color: #495057;
    text-align: center;
    background-color: #ffffff;
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #67757c;
    border-color: #67757c;
    box-shadow: none;
}

.btn-warning,
.btn-warning.disabled {
    background: rgb(255, 102, 34);
    color: #ffffff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    border: 1px solid #ec6333;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
    background: #398bf7;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border: 1px solid #398bf7;
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
    background: #e9ab2e;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border-color: transparent;
}

.btn-warning__morado,
.btn-warning__morado.disabled {
    background: #2a22df;
    color: #ffffff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    border: 1px solid #2a22df;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}

// AGREGADO
.btn-warning__social,
.btn-warning__social.disabled {
    background: #1464f4;
    color: #ffffff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    border: 1px solid #1464f4;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}

//------------------------
.btn-rounded.btn-lg {
    padding: 14px 11px 22px 7px;
    width: 85%;
    margin: 0 auto;
    font-size: 120%;
    border-radius: 21px;
    font-weight: 700;
}

.btn-thanks__login {
    width: 106%;
    font-size: 17px;
    font-weight: bold;
    margin: 0% 0% 0% 34%;
    height: 39px;
}

.btn-thanks__loginvolver {
    width: 106%;
    font-size: 17px;
    font-weight: bold;
    margin: 0% 0% 0% 34%;
    height: 39px;
}

.btn-facebook {
    color: #ffffff;
    background-color: #1663f6;
    margin: 0 auto;
    margin-left: auto;
    margin-left: auto;
    width: 51%;
    margin-left: 29%;
    padding: 14px 11px 22px 7px;
    font-size: 20px;
}

.text-info {
    color: rgb(18, 6, 57) !important;
    text-decoration: underline !important;
    text-align: center;
}

.iconoBlueheader {
    color: rgb(28, 12, 79);
}

.relleno {
    height: 40px;
    background: white;
}

.ulHeadera a {
    font-size: 1rem;
    color: rgb(28, 12, 79);
    font-weight: 400;
}

.img-width-100 {
    width: 120px;
}

.img-categories {
    width: 100%;
}

.mg {
    margin: 5px 0px;
    padding: 20px 0px;
}

.auto-align {
    margin: auto;
}

.pricing-orange {
    color: #ec6334;
    font-size: 20px;
}

.click {
    cursor: pointer;
}

.vehicle-selected {
    background-color: #fbeee9;
    border-left: 10px solid #eb6133;
}

.btn-primary,
.btn-primary.disabled {
    background: #1d124f;
    border: 1px solid #1d124f;
}

.color-orange {
    color: #ec6334;
    font-size: 22px;
}

.color-white {
    color: #fff;
    font-size: 22px;
}

.mtop-botton {
    margin: 20px 0px;
}

.centrado-li {
    padding-left: 31%;
    padding-right: 31%;
    padding-bottom: 23px;
    padding-top: 22px;
}

.color-blue__titulothankscompany {
    color: #1d124f;
    font-weight: bold;
    font-size: 30px;
    padding-top: 6%;
}

.color-blue {
    color: #1d124f;
    font-weight: bold;
}

.color-blue_pricingconfirmar {
    color: #1d124f;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    padding-top: 21px;
}

.color-blue_confirmar {
    color: #1d124f;
    font-weight: bold;
    font-size: 30px;
    padding-bottom: 33px;
    padding-top: 26px;
}

.color-blue_labelpago {
    color: #1d124f;
    font-weight: 400;
    padding-left: 35px;
}

.btn-blue {
    background: rgb(42, 34, 223);
    border: 1px solid rgb(42, 34, 223);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    transition: 0.2s ease-in;
    color: #fff;
}

.text-registrar {
    font-weight: bold;
    font-size: 33px;
    color: rgb(18, 6, 57);
    padding-bottom: 10%;
}

.text-thankscompany {
    text-align: left;
    padding-top: 22px;
    color: rgb(28, 12, 139);
    font-weight: revert;
    margin-bottom: -5px;
}

.text-thankscompany2 {
    color: #000;
    font-size: 24px;
    text-align: left;
    margin-bottom: -28px;
}

.text-thankscompany__driver {
    color: #000;
    font-size: 24px;
    text-align: left;
    margin-bottom: -28px;
}

.text-subtitulo {
    color: rgb(28, 12, 139);
    font-weight: bold;
    margin-bottom: 25%;
}

.text-subtitulo__driver {
    color: #1c0c8b;
    font-weight: bold;
    margin-bottom: 25%;
    font-size: 19px;
    margin-top: 27px;
    margin-bottom: 84px;
}

.top-bottoms {
    margin-bottom: 21px;
}

// .btn-secondary {
//     color: #1d124f !important;
//     font-weight: 500;
// }

.btn-secondary {
    background: #1d124f !important;
    font-weight: 500;
    color: #fff !important;
}

.kart {
    font-size: 30px;
    margin-right: -18px;
}

.cart-items {
    background: #eee;
    text-align: center;
    /* padding: 23%; */
    border-radius: 50%;
    margin-top: -9px;
    color: #eb6333;
    border: 1px solid #eb6333;
    font-weight: 500;
    width: 50px;
    height: 50px;
    padding: 12px;
}

.cats {
    display: inline-block;
    width: 19%;
}

.modal-header {
    background: #eb6333 !important;
    color: #fff !important;
}


.modal-title {
    color: #fff !important;
}

.invalid {
    color: red;
}

.time-arrived {
    border: 1px solid #eb6333;
    padding: 5px 10px;
    color: #1d124f !important;
    font-weight: 600;
}

.border-bottom-orange {
    border-bottom: 20px solid #eb6333 !important;
}

.services-order {
    background: #eb6333;
    color: #fff;
    padding: 9px 10px;
    margin-top: -10px;
    margin-bottom: -10px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}

.labels {
    border-radius: 10px;
    padding: 5px 10px;
}

.status-terminado {
    background-color: #73b955;
    color: #fff;
}

.status-anulado {
    background-color: #fc9732;
    color: #fff;
}

.status-cancelado {
    background-color: #e83e33;
    color: #fff;
}

.status-repetir {
    background-color: #baea36;
    color: #fff;
}

.status-pendiente {
    background-color: #f8c539;
    color: #fff;
}

.clear-button {
    background: none;
    border: none;
    color: #1d124f;
    margin-top: 30px;
    box-shadow: none;
}

.mtop-negative {
    margin-top: 2px;
}

.dash-pricing {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: -11px;
    color: #1d124f;
}

.table-spacing-h {
    margin: 2px 0px;
    padding: 11px 0px;
}

.hover-efect:hover {
    background: #eee;
    cursor: pointer;
}

.cont-login {
    min-height: 100vh !important;
}

.login-company {
    background: rgb(43, 36, 223);
    height: 100%;
}

.login-user {
    background: rgb(255, 102, 34);
    /*height: 100%;*/
    min-height: 100vh !important;
}

.cont-white {
    background: #fff;
    padding-bottom: 18%;
    margin-top: auto;
}

.pd-forms-login {
    padding: 0 8% !important;
    min-height: 100vh !important;
}

.contenedorRegisterComponent {
    padding-top: 8% !important;
}

.tituloEmpresa {
    font-size: 30px !important;
}

.ImgPerfil {
    display: flex;
    width: 35px;
    height: 35px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #ec6334;
    margin: 10px auto 0 auto;
}

.profile-pic {
    width: 40px !important;
}

.profile-pic2 {
    width: 50px !important;
}

.mail-desc {
    color: #ec6334 !important;
    font-weight: bold;
}

.nServicio {
    color: black;
    font-size: 15px;
}

.mailboxBell {
    width: 400px !important;
}

.mail-contnet {
    margin-left: 25px;
}


/*.mdi-account{
    width: 100%;
}/*

/*.spacer-100{
    height:100px;
}*/

.img-80 {
    width: 95%;
    padding-bottom: 15%;
    padding-top: 35%;
}

.img-company {
    width: 66%;
    padding-bottom: 25%;
    padding-top: 14%;
}

.p-login {
    line-height: 100%;
    font-weight: 300;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 34px;
    font-family: "Barlow Semi Condensed", sans-serif;
}

.p-logindriver {
    line-height: 100%;
    font-weight: 300;
    padding-top: 34px;
    font-family: "Barlow Semi Condensed", sans-serif;
    text-align: center;
}

agm-map {
    height: 100%;
    width: 101%;
    left: 5%;
    position: fixed;
    top: 70px;
}

.pd-forms-register {
    padding: 0 8%;
    min-height: 100vh;
}

.pd-forms-registerdriver {
    /*padding: 8% 8% 8% 8%;*/
    padding: 0 8%;
}

.titulo-acarreo {
    color: #ffffff;
    font-size: 39px;
    font-weight: bold;
    padding-bottom: 5%;
}


/* @media (max-width: 600px) {
    .facet_sidebar {
      display: none;
    }
  } */

@media (max-width: 600px) {
    .no-display-movil {
        display: none;
    }
}

.btn-outline-secondary {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    margin-top: 10px;
}

.input-register {
    background: rgb(247, 246, 246) !important;
    border-radius: 21px !important;
    //padding: 16px 0px 20px 35px !important;
    padding: 16px 0px 10px 15px !important;
    margin: 4px 0px;
    color: #9c9c9c;
    position: relative !important;
    z-index: 1 !important;
}

.input-register::placeholder {
    color: #9c9c9c;
}

.input-login {
    background: #f7f6f6 !important;
    margin: 2px 0px;
    //padding: 14px 11px 22px 31px  !important;
    padding: 14px 11px 12px 21px !important;
    border-radius: 21px !important;
    position: relative !important;
    z-index: 1 !important;
}

.input-login-company {
    background: #f7f6f6 !important;
    padding: 14px 11px 22px 31px !important;
    border-radius: 21px !important;
}

.btn-info,
.btn-info.disabled {
    background: rgb(255, 102, 34);
    border: 1px solid rgb(255, 102, 34);
    -webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    border-radius: 300px;
    width: 74%;
    font-weight: bold;
}

.facebook-register {
    color: #ffffff;
    background-color: #1663f6;
    margin: 0 auto;
    width: 81%;
    margin-left: 9%;
    padding: 8px 0px 8px 0px;
    font-weight: bold;
}

.titulo-confirmar {
    color: #ffffff;
    font-size: 39px;
    font-weight: bold;
    padding-top: 8% !important;
    padding-bottom: 5%;
}

.text-confirmatucorreo {
    font-weight: bold;
    font-size: 198%;
    padding-bottom: 27px;
}

.text-beneficioskamgus {
    font-weight: bold;
    font-size: 31px;
    font-style: italic;
}

.form-horizontal label {
    // margin-bottom: 0px;
    font-weight: bold;
    // padding-left: 0px;
}


.btn-facebook {
    border-radius: 22px;
}


/*menu*/

.ImgPerfil2 {
    display: flex;
    width: 50px;
    height: 50px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #ec6334;
    margin: 10px auto 0 auto;
}

.iconoPerfil {
    font-size: 24px;
    position: absolute;
    top: 10px;
    left: 20px;
    width: 50px;
    height: 50px;
}

.iconoPerfil2 {
    font-size: 24px;
    position: absolute;
    top: 34px;
    left: 10%;
    color: white;
}

.textPerfil {
    width: 80%;
    display: flex;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.textPerfil h4 {
    font-size: 20px !important;
}

.text-muted {
    font-size: 15px !important;
}


/*FIN HEADER*/

@media (max-width: 100px) {
    .facet_sidebar {
        display: none;
    }
}

.card-pricing {
    box-shadow: 1px 1px 5px #c7c4c4;
    border-radius: 20px;
    padding-left: 20px;
    margin: 0px 22%;
    padding: 27px 0px 28px 0px;
}

.card-pricing--driver {
    box-shadow: 1px 1px 5px #c7c4c4;
    border-radius: 20px;
    padding: 20px;
    /*padding-left: 20px;
    padding-left: 20px;*/
    margin: 0 0;
    //padding-left: 33px;
}

.card-pricing__suscripcion {
    margin: 0px 0% 0% 0%;
    padding: 0px 0px 0px 0px;
    box-shadow: 1px 1px 5px #c7c4c4;
    border-radius: 20px;
}

.pd-thank-you {
    padding: 0px 30%;
}

.box-payment {
    background: #170b39;
    padding: 24px;
    border-radius: 16px;
}

.box-recuerdame {
    text-align: center;
    width: 80%;
    margin-left: 10%;
    /*padding-left: 251px;*/
    /*border:solid black 1px;*/
}

.text-Recuerdame {
    font-weight: bold !important;
    color: #000;
    font-size: 15px;
}

.btn-info--register {
    background: rgb(42, 34, 233);
    border: 1px solid rgb(42, 34, 233);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    transition: 0.2s ease-in;
    border-radius: 300px;
    width: 81%;
    font-weight: bold;
    color: #fff;
}

.btn-info--registerdriver {
    background: rgb(42, 34, 233);
    border: 1px solid rgb(42, 34, 233);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    transition: 0.2s ease-in;
    border-radius: 300px;
    width: 81%;
    font-weight: bold;
    color: #fff;
    padding: 2%;
}
.btn-info--registerdriver-outline {
    color: rgb(42, 34, 233);
    border: 1px solid rgb(42, 34, 233);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    transition: 0.2s ease-in;
    border-radius: 300px;
    width: 81%;
    font-weight: bold;
    background: #fff;
    padding: 2%;
}

.titulo-thnaks {
    font-weight: bold;
    font-family: "Barlow Semi Condensed", sans-serif;
    color: rgb(255, 102, 36);
    font-size: 35px;
}

.text-thanyou__yapuedes {
    padding: 0px !important;
    font-weight: bold;
    color: rgb(255, 102, 36);
}

.espacio-division {
    padding-bottom: 54px;
}

.btn-facebook--thnksyou {
    background-color: #2a22df;
    width: 128%;
    padding: 5px 0px 3px 0px;
    font-size: 17px;
    border-radius: 30px;
    margin: 0% 0% 0% -2%;
    font-weight: bold;
    height: 39px;
}

.btn-facebook--thnksyoucompany {
    background-color: #ff6622;
    width: 128%;
    padding: 5px 0px 3px 0px;
    font-size: 17px;
    border-radius: 30px;
    margin: 0% 0% 0% 25%;
    font-weight: bold;
    height: 39px;
}

.terminosP {
    margin-left: 6%;
}


/*
.textoAcepto{
    color:#007bff;
}*/


/*media queries */

@media only screen and (min-width: 360px) and (max-width: 640px) {
    .box-recuerdame {
        /*text-align: center; 
        padding: 0px 125px 0px 121px;*/
        padding: 0 5%;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 768px) {
    /* .box-recuerdame{
        text-align: center; 
        padding-left: 0px;
    }*/
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 19206px) and (max-width: 1080px) {
    /*.box-recuerdame{
        text-align: center; 
        padding-left: 0px;
    }*/
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
    .box-recuerdame {
        /*text-align: center;*/
        padding: 0px 5%;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 900px) {
    /*.box-recuerdame{
        text-align: center; 
        padding-left: 0px;
    }*/
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 720px) and (max-width: 1280px) {
    /*.box-recuerdame{
        text-align: center;
        padding: 0px 125px 0px 121px;
    }*/
    .btn-rounded.btn-lg {
        padding: 0.75rem 1.5rem;
        width: 74%;
        margin: 0 auto;
        margin-left: auto;
        margin-left: auto;
        margin-left: auto;
        margin-left: 13%;
        font-size: 124%;
    }
    .btn-facebook {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        width: 73%;
        font-size: 110%;
    }
    .btn-facebook__driver {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        width: 43%;
        font-size: 80%;
        border-radius: 22px;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
    /* Iphone 5*/
    /*.box-recuerdame{
        text-align: center;
        padding: 0px 125px 0px 121px;
    }*/
    .btn-rounded.btn-lg {
        padding: 0.75rem 1.5rem;
        width: 76%;
        margin: 0 auto;
        margin-left: auto;
        margin-left: auto;
        margin-left: auto;
        margin-left: 13%;
        font-size: 124%;
    }
    .btn-facebook {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        width: 82%;
        font-size: 91%;
        padding: 15px 0px 19px 5px;
        border-radius: 33px;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
    /*Iphone X/Xs*/
    /*.box-recuerdame{
        text-align: center;
        padding: 0px 125px 0px 121px;
      
    }*/
    .btn-facebook {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        width: 76%;
        font-size: 90%;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (min-width: 414px) and (max-width: 736px) {
    /*GALAXY S9*/
    /* .box-recuerdame{
        text-align: center;
        padding: 0px 125px 0px 121px;
     
    }*/
    .btn-facebook {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        margin-left: auto;
        margin-left: auto;
        margin-left: auto;
        width: 76%;
        margin-left: 13%;
        font-size: 104%;
    }
    .centrado-inputs {
        width: 90%;
        padding-left: 10%;
    }
}

@media only screen and (max-width: 800px) and (min-height: 0px) {
    /*.box-recuerdame {
        padding: 15px 62px 0px 45px;
        margin: -24px 0px -11px 11px;
        font-size: 84%;
    }*/
    .centrado-inputs {
        width: 86%;
        padding-left: 12%;
    }
    .text-info {
        color: #120639 !important;
        text-decoration: underline !important;
        padding-left: 0% !important;
    }
    /*.text-olvide {
        padding-left: 0px;
        float: right;
        text-align: right;
    }*/
    .text-Recuerdame {
        font-weight: bold;
        color: #000;
        font-size: 92% !important;
    }
    .input-login-company {
        background: #f7f6f6 !important;
        border-radius: 21px !important;
        width: 83%;
        margin: 0px 0px 6px 39px;
    }
    .pd-thank-you {
        padding: 0px 9%;
    }
    .responsive-thank {
        position: relative;
        padding-left: 42px;
        width: 0px;
        padding-right: 108px;
    }
    .responsive-thank__btn {
        padding-right: 108px;
        width: 6%;
        position: absolute;
        bottom: 5%;
    }
    .btn-thanks__login {
        padding: 3px 133px 5px 26px;
        font-size: 14px;
        border-radius: 30px;
        font-weight: bold;
        margin: 15px 36px 0px -84px;
    }
    .btn-facebook--thnksyou {
        background-color: #2a22df;
        font-size: 14px;
        margin: 1px 0px 0px 34px;
        padding: 7px 148px 7px 22px;
    }
    .card-pricing {
        box-shadow: 1px 1px 5px #c7c4c4;
        border-radius: 20px;
        padding: 20px;
        /*padding-left: 20px;
        padding-left: 20px;*/
        margin: 0px 0px;
        //padding-left: 33px;
    }
    .text-subtitulo__driver {
        color: #1c0c8b;
        font-weight: bold;
        margin-bottom: 25%;
        font-size: 17px;
        margin-top: 27px;
        margin-bottom: 45px;
    }
    .btn-facebook__driver {
        color: #ffffff;
        background-color: #1663f6;
        margin: 0 auto;
        width: 63%;
        font-size: 20px;
        border-radius: 22px;
        padding: 1% 0% 1% 0%;
    }
    .btn-facebook--thnksyoucompany {
        background-color: #ff6622;
        width: 128%;
        padding: 6px 163px 9px 20px;
        font-size: 17px;
        border-radius: 30px;
        margin: 0% 0% 0% 25%;
        font-weight: bold;
        height: 39px;
    }
}


/* COMPUTADORAS PEQUEÑAS*/

@media only screen and (min-width: 800px) and (max-width: 1286px) {
    .btn-thanks__login {
        width: 106%;
        font-size: 17px;
        font-weight: bold;
        margin: 0% 0% 0% 0%;
        height: 39px;
    }
    .btn-facebook--thnksyou {
        background-color: #2a22df;
        width: 128%;
        padding: 5px 0px 3px 0px;
        font-size: 17px;
        border-radius: 30px;
        margin: 0% 0% 0% 25%;
        font-weight: bold;
        height: 39px;
    }
}


/* Desktops and laptops – ------- – */

@media only screen and (min-width: 1286px) and (min-height: 0px) {
    /*.box-recuerdame {
        padding: 24px 62px 0px 19px;
        margin: -21px 3px -10px 75px;
        font-size: 84%;
    }*/
    .centrado-inputs {
        width: 98%;
        padding-left: 2%;
    }
    .btn-rounded.btn-lg {
        padding: 14px 11px 22px 7px;
        width: 85%;
        font-size: 155%;
        border-radius: 21px;
        font-weight: bold;
        margin: 0 auto;
    }
    .btn-facebook {
        color: #fff;
        background-color: #1663f6;
        border-radius: 20px;
        font-weight: 700;
        margin: 0 auto;
        border-radius: 30px;
        width: 74%;
        font-weight: 700;
        font-size: 134%;
    }
    .btn-thanks__login {
        width: 106%;
        font-size: 17px;
        font-weight: bold;
        margin: 0% 0% 0% -23%;
        height: 39px;
    }
}


/*FIN CONPUTADORAS PEQUEÑAS*/


/*FIN DE RESPONSIVE*/


/*CAMPANY*/

.color-white-company {
    color: #fff;
    font-size: 47px;
    font-weight: bold !important;
}

.color-white-driver {
    color: #fff;
    font-size: 23px;
    font-weight: bold !important;
}

.titulo-metodos {
    font-weight: bold;
    padding-bottom: 17px;
    font-size: 24px;
    padding-top: 37px;
}

.btn-facebook__driver {
    color: #ffffff;
    background-color: #1663f6;
    margin: 0 auto;
    width: 62%;
    font-size: 20px;
    border-radius: 22px;
    padding: 1% 0% 1% 0%;
}

.close-x{
    color: white !important;
    font-weight: bold;
    cursor: pointer;
}
